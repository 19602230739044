const listProductsDespensaBar = [

    {
        id: 1,
        src: require("@/images/despensa/img1.png"),
        name: "Brownies de chocolate",
        code: "LM-DDB001",
        flex: 12,
    },
    {
        id: 2,
        src: require("@/images/despensa/img2.jpg"),
        name: "Brownies corazón",
        code: "LM-DDB002",
        flex: 12,
    },
    {
        id: 3,
        src: require("@/images/despensa/img3.png"),
        name: "Caja con grabado",
        code: "LM-DDB003",
        flex: 12,
    },
    {
        id: 4,
        src: require("@/images/despensa/img4.jpg"),
        name: "Caja con lazo de tela",
        code: "LM-DDB004",
        flex: 12,
    },
    {
        id: 5,
        src: require("@/images/despensa/img5.png"),
        name: "Porta botella en bio-cuero",
        code: "LM-DDB005",
        flex: 12,
    },
    {
        id: 6,
        src: require("@/images/despensa/img6.png"),
        name: "Porta botella en cuero con asa de metal",
        code: "LM-DDB006",
        flex: 12,
    },
    {
        id: 7,
        src: require("@/images/despensa/img7.png"),
        name: "Caja blanca con lazo",
        code: "LM-DDB007",
        flex: 12,
    },
    {
        id: 8,
        src: require("@/images/despensa/img8.jpg"),
        name: "Macerado de pisco de diferentes sabores",
        code: "LM-DDB008",
        flex: 12,
    },
    {
        id: 9,
        src: require("@/images/despensa/img9.jpg"),
        name: "Bolsa pequeña con dulces e impresión",
        code: "LM-DDB009",
        flex: 12,
    },
    {
        id: 10,
        src: require("@/images/despensa/img10.jpg"),
        name: "Macerado de pisco",
        code: "LM-DDB010",
        flex: 12,
    },
    {
        id: 11,
        src: require("@/images/despensa/img11.png"),
        name: "Postre con Etiqueta en Envase",
        code: "LM-DDB011",
        flex: 12,
    },
  ]

  export { listProductsDespensaBar }
